.ReactTable {
  border: none;

  & .rt-table {
    overflow: hidden;
    height: calc(100vh - 186px);
  }

  & .-header {
    display: none;
  }

  & .rt-tr-group {
    max-height: 40px;
    border-bottom: $border;
    font-size: 12pt;
    font-weight: 300;

    &:hover {
      background-color: #F2F2F2;
    }
  }

  & .rt-tr {
    background-color: transparent;
    color: black;
    padding-left: 15px;

    & svg {
      color: $color-text;
    }

    &.-selected {
      background-color: rgba(2, 184, 117, .2);
    }

   & .patients__file {
      cursor: pointer;
      color: $color-primary;
      font-size: 25px;

     &_primary {
       color: $color-primary;
     }

     &_danger {
       color: $color-danger;
     }

     &_warning {
       color: $color-warning;
     }

     &_success {
       color: $color-success;
     }
    }
  }

  & .rt-td {
    padding: 8px 12px;
  }

  & .rt-tbody .rt-td {
    color: inherit;
    border: none;
    cursor: pointer;
  }

  & .rt-tbody {
    min-width: 100% !important;
  }

  &__maskCell {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 12pt;
    font-weight: 300;
    cursor: pointer;
    color: inherit;
  }
}

.tableWrapper {
  overflow: auto;

  & .ReactTable {
    & .rt-table {
      overflow: auto !important;
    }

    & .rt-tbody {
      overflow: visible !important;
    }
  }

  &_mr {
    border: $border;

    & .ReactTable .rt-table {
      height: calc(100vh - 211px);
    }
  }
}
