.form {
  color: $color-text;

  &_horizontal {
    display: flex;
    justify-content: space-between;

    & .form-group {
      padding: 0 8px;
      margin: 0;
      position: relative;
      flex: 100 0 auto;
      width: 100px;

      @media (min-width: 576px) {
        flex-shrink: 0;
      }

      &_borderNone {
        & input {
          border: none;
          outline: none;
          background: transparent;
        }
      }

      &_center {
        & input {
          text-align: center;
        }
      }
    }
  }

  &_small {
    font-size: 13px;

    @media (min-width: 576px) {
      font-size: 14px;
    }
  }

  & .custom-control {
    display: flex;
  }

  & .form-control {
    font-size: 13px;
    padding: 5px 8px;

    @media (min-width: 576px) {
      font-size: 14px;
    }
  }

  & .custom-control-label {
    display: flex;
    align-items: center;
  }
}

.sortIcon {
  position: absolute;
  right: 16px;
  cursor: pointer;
  bottom: 50%;
  transform: translateY(50%);
}

.btn:focus, .btn.focus {
  box-shadow: none;
}

button:focus {
  outline: none;
}
