@charset "UTF-8";
@import url(../../node_modules/bootstrap/dist/css/bootstrap.min.css);
@import url(../../node_modules/bootswatch/dist/litera/bootstrap.min.css);
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto-regular-webfont.woff2") format("woff2"), url("../fonts/roboto-regular-webfont.woff2") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto-bold-webfont.woff2") format("woff2"), url("../fonts/roboto-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

.wrapper {
  height: 100%; }
  @media (min-height: 667px) {
    .wrapper {
      height: calc(100vh - 58px); } }
  .wrapper_scroll .tab-content {
    height: calc(100vh - 152px);
    overflow: auto; }
  .wrapper__column_border {
    border-right: 1px solid #F2F2F2; }
  .wrapper .btn {
    border: 1px solid #F2F2F2;
    border-radius: 4px;
    background: #FEFEFE;
    margin-right: 3px;
    line-height: normal;
    min-width: 36px; }
    .wrapper .btn-outline-secondary {
      color: #777777; }
      .wrapper .btn-outline-secondary:hover {
        color: #fff;
        background-color: #adb5bd;
        border-color: #adb5bd; }
    .wrapper .btn-success {
      color: #fff;
      background-color: #02B875;
      border-color: #02B875; }
  .wrapper .nav-link {
    padding: 11px 16px; }
  .wrapper .nav {
    padding-left: 15px; }

.form {
  color: #777777; }
  .form_horizontal {
    display: flex;
    justify-content: space-between; }
    .form_horizontal .form-group {
      padding: 0 8px;
      margin: 0;
      position: relative;
      flex: 100 0 auto;
      width: 100px; }
      @media (min-width: 576px) {
        .form_horizontal .form-group {
          flex-shrink: 0; } }
      .form_horizontal .form-group_borderNone input {
        border: none;
        outline: none;
        background: transparent; }
      .form_horizontal .form-group_center input {
        text-align: center; }
  .form_small {
    font-size: 13px; }
    @media (min-width: 576px) {
      .form_small {
        font-size: 14px; } }
  .form .custom-control {
    display: flex; }
  .form .form-control {
    font-size: 13px;
    padding: 5px 8px; }
    @media (min-width: 576px) {
      .form .form-control {
        font-size: 14px; } }
  .form .custom-control-label {
    display: flex;
    align-items: center; }

.sortIcon {
  position: absolute;
  right: 16px;
  cursor: pointer;
  bottom: 50%;
  transform: translateY(50%); }

.btn:focus, .btn.focus {
  box-shadow: none; }

button:focus {
  outline: none; }

.notification {
  font-family: 'Roboto', sans-serif;
  max-width: 400px;
  width: calc(100% - 50px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  background: white;
  padding: 15px;
  position: relative;
  margin: 10px 25px; }
  .notification.notification--image {
    height: 106px;
    overflow: hidden; }
  .notification.notification--full-wide {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    padding: 20px 40px 20px 25px; }
  .notification.notification--primary {
    background: #70bbfd; }
    .notification.notification--primary .notification__message, .notification.notification--primary .notification__title {
      color: white; }
  .notification.notification--success {
    background: #02B875; }
    .notification.notification--success .notification__message, .notification.notification--success .notification__title {
      color: white; }
  .notification.notification--warning {
    background: #f0ad4e; }
    .notification.notification--warning .notification__message, .notification.notification--warning .notification__title {
      color: white; }
  .notification.notification--danger {
    background: #d9534f; }
    .notification.notification--danger .notification__message, .notification.notification--danger .notification__title {
      color: white; }

.notification__message {
  font-family: 'Roboto', sans-serif;
  margin-top: 0;
  font-size: 18px;
  color: #777777; }

.notification__title {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 8px;
  color: #646777;
  min-height: 25px; }

.notification__image {
  position: absolute;
  height: 106px;
  width: 106px;
  top: 0;
  left: 0; }
  .notification__image ~ * {
    padding-left: 106px; }

.sidebar.sidebar--collapse + .container__wrap .notifications-tl, .sidebar.sidebar--collapse + .container__wrap .notifications-bl {
  left: 0 !important; }

.rc-notification {
  position: fixed;
  z-index: 100; }

.rc-notification-notice {
  display: block;
  width: auto;
  line-height: 1.5;
  vertical-align: middle;
  position: relative; }

.rc-notification-notice-close {
  position: absolute;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  line-height: 1;
  opacity: .2;
  text-decoration: none; }

.rc-notification-notice-close-x:after {
  content: '×'; }

.rc-notification-fade-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused; }

.rc-notification-fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused; }

.rc-notification-fade-enter.rc-notification-fade-enter-active {
  animation-name: rcNotificationFadeIn;
  animation-play-state: running; }
  .rc-notification-fade-enter.rc-notification-fade-enter-active.right-up {
    animation-name: rcNotificationRightFadeIn; }
  .rc-notification-fade-enter.rc-notification-fade-enter-active.left-up {
    animation-name: rcNotificationLeftFadeIn; }

.rc-notification-fade-leave.rc-notification-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running; }

@keyframes rcNotificationFadeIn {
  0% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes rcNotificationLeftFadeIn {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes rcNotificationRightFadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes rcDialogFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(-100%);
    opacity: 0; } }

.rc-notification {
  left: auto !important;
  top: 60px !important;
  height: 0; }
  .rc-notification > span {
    height: 0;
    display: block; }

.rc-notification-notice-close {
  right: 45px;
  top: 15px;
  font-size: 26px;
  line-height: 1;
  font-weight: 300;
  color: white;
  text-shadow: none;
  opacity: 0.8; }
  .rc-notification-notice-close:hover {
    opacity: 0.5;
    color: #646777; }

.cards {
  padding: 17px 24px;
  background: #FFFFFF;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 14px;
  margin-left: 20px;
  width: 280px; }
  .cards__title {
    margin: 0;
    font-size: inherit; }
  .cards__content {
    color: #777777; }
  .cards__block {
    padding-bottom: 21px; }
  .cards__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    font-size: 24px; }
    .cards__row span {
      display: block;
      font-size: 12px;
      font-weight: 300;
      margin-left: 12px; }

.visitContainer {
  background: #FCFCFC;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 25px 20px; }
  .visitContainer__header {
    padding: 13px;
    font-weight: normal;
    line-height: normal;
    color: white;
    margin-bottom: 1rem;
    border-radius: 4px;
    font-size: 13px; }
    @media (min-width: 576px) {
      .visitContainer__header {
        font-size: 18px; } }
    .visitContainer__header h5 {
      margin: 0;
      font-size: inherit; }
    .visitContainer__header .custom-control-label {
      font-size: inherit; }
    .visitContainer__header_success {
      background: #02B875; }
    .visitContainer__header .custom-control-input:disabled ~ .custom-control-label {
      color: white; }
  .visitContainer__subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #777777; }
  .visitContainer__separator {
    margin: 1rem 0; }
    .visitContainer__separator:after {
      content: '';
      display: block;
      height: 12px;
      background-color: #02B875; }

.mainWrapper {
  height: 100vh; }

.btn-scroll {
  padding: 10px 25px;
  margin-bottom: 20px;
  margin-right: 15px;
  font-size: 14px;
  overflow: hidden;
  z-index: 0;
  line-height: 1;
  opacity: 0;
  position: fixed;
  right: 15px;
  bottom: 15px;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  transition: opacity ease .3s; }
  .btn-scroll_show {
    opacity: 1; }

.patients .btn {
  border: 1px solid #F2F2F2;
  border-radius: 4px;
  background: #FEFEFE;
  margin-right: 3px;
  line-height: normal;
  min-width: 36px; }
  .patients .btn-outline-secondary {
    color: #777777; }
    .patients .btn-outline-secondary:hover {
      color: #fff;
      background-color: #adb5bd;
      border-color: #adb5bd; }

.patients__column_bg {
  background-color: #FCFCFC;
  border-right: 1px solid #F2F2F2;
  min-width: 310px; }

.patients .rt-table {
  height: calc(100vh - 186px); }

.patients .ReactTable .rt-tr .patients__action {
  cursor: pointer;
  color: #02B875;
  font-size: 25px; }

.patients .ReactTable .rt-tr.-selected .patients__action {
  color: white; }

.patients .rt-tbody .rt-td {
  cursor: auto !important; }

.patients__hadsCard {
  background: #FEFEFE;
  border: 1px solid #CFCFCF;
  border-radius: 8px;
  color: #777777;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  height: 100%;
  margin-bottom: 25px; }
  .patients__hadsCard .form-group {
    padding: 19px;
    padding-bottom: 0;
    margin: 0;
    width: 100%; }
  .patients__hadsCard:before {
    display: none; }
    @media (min-width: 1020px) {
      .patients__hadsCard:before {
        display: flex; } }
  .patients__hadsCard:before {
    content: 'T';
    width: 41px;
    min-width: 41px;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #CFCFCF; }
  .patients__hadsCard_gray {
    background: #F8F8F8; }
    .patients__hadsCard_gray:before {
      content: 'Д'; }
  .patients__hadsCard_beforeNone:before {
    display: none; }

.patients__table {
  overflow: auto; }

.patients .table {
  font-size: 13px;
  color: #777777; }
  @media (min-width: 576px) {
    .patients .table {
      font-size: 14px; } }

.patients .btn-scroll {
  padding: 10px 25px;
  margin-bottom: 20px;
  margin-right: 15px;
  font-size: 14px;
  overflow: hidden;
  z-index: 0;
  line-height: 1;
  opacity: 0;
  position: fixed;
  right: 15px;
  bottom: 15px;
  border-radius: 100%;
  background-color: #70bbfd;
  border: none;
  width: 50px;
  height: 50px;
  transition: opacity ease .3s; }
  .patients .btn-scroll_show {
    opacity: 1; }

.visitCard {
  padding: 20px;
  background: #FCFCFC;
  border: 1px solid #CFCFCF;
  box-sizing: border-box;
  border-radius: 8px;
  height: 100%; }
  .visitCard_pointer {
    cursor: pointer; }
  .visitCard__add {
    font-size: 126px; }
  .visitCard__delete {
    cursor: pointer;
    color: #777777; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 100%; }

button {
  background: transparent;
  outline: none;
  border: none;
  background: none; }

main {
  overflow: hidden; }
