.btn-scroll {
    .patients {

    }
    padding: 10px 25px;
    margin-bottom: 20px;
    margin-right: 15px;
    font-size: 14px;
    overflow: hidden;
    z-index: 0;
    line-height: 1;
    opacity: 0;
    position: fixed;
    right: 15px;
    bottom: 15px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    transition: opacity ease .3s;

&_show {
        opacity: 1;
    }
}
