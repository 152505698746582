.tablePagination {
  padding: 5px;
  padding-left: 15px;
  background-color: $color-panel;
  font-size: 16px;
  color: $color-text;
  height: 42px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: $border;

  &__buttons {
    padding-left: 12px;
    white-space: nowrap;
  }

  &__info {
    font-size: 12px;

    @media (min-width: 430px) {
      font-size: 16px;
    }
    & span {
      text-overflow: ellipsis;
      max-width: 49px;
      display: inline-block;
      overflow: hidden;

      @media (min-width: 375px) {
        max-width: 100%;
      }
    }
  }

  &__control {
    width: 40px;
    border-radius: 4px;
    padding: 6px;
    font-size: inherit;
    border: $border;
    color: $color-text;
    line-height: normal;
    text-align: right;
    margin: 0 8px;

    @media (min-width: 430px) {
      width: 83px;
    }

       &:focus {
         outline: 1px solid #b9d0f8;
       }
  }
}
