.login-card {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding: 16px;
}

.login-card h4{
    color: #777;
    font-weight: 400;
    margin-bottom: 32px;
}

.login-card form label {
    color: #777;
}

.ttr-form-footer {
    margin-top: 42px;
}

.btn-link.ttr-btn-link {
    text-decoration: none;
    color: #999;
}

.left-line {
    border-left: solid 1px #02A855
}

.top-line {
    width: 100%;
    height: 50px;
    background: rgba(80, 181, 123, 0.59);
}

.logo {
    width: 180px;
    height: 100px;
    background-image: url('../../../img/logo.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    margin-left: 20px;
    filter: drop-shadow(0px 2px 4px #444);
}

.description {
    color: #FFF;
    text-align: justify;
    padding-top: 150px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.description h3 {
    font-size: 24pt;
    font-weight: 400;
}

.description span{
    font-size: 16pt;
    font-weight: 100;
}

.login-form .login-form__link {
    text-decoration: none;
    color: #999;
    cursor: pointer;
}
