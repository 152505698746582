.visitContainer {
  background: $color-panel;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 25px 20px;

  &__header {
    padding: 13px;
    font-weight: normal;
    line-height: normal;
    color: white;
    margin-bottom: 1rem;
    border-radius: 4px;
    font-size: 13px;

    @media (min-width: 576px) {
      font-size: 18px;
    }

    & h5 {
      margin: 0;
      font-size: inherit;
    }

    & .custom-control-label{
      font-size: inherit;
    }

    &_success {
      background: $color-success;
    }

    & .custom-control-input:disabled ~ .custom-control-label {
      color: white;
    }
  }

  &__subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: $color-text;
  }

  &__separator {
    margin: 1rem 0;
    &:after {
      content: '';
      display: block;
      height: 12px;
      background-color: $color-success;
    }
  }
}
