.tableHeader {
  background-color: $color-panel;
  padding: 5px;
  padding-left: 15px;
  border-bottom: $border;

  &__icon {
    color: $color-theme;
    font-size: 25px;
    line-height: normal;
    padding-left: 5px;
  }

  &__title {
    font-size: 18px;
    line-height: normal;
    color: $color-text;
    font-weight: normal;
  }

  &__count {
    display: block;
    font-size: 14px;
    line-height: normal;
    text-align: center;
    color: #FFFFFF;
    background-color: $color-success;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 5px;

    &_inline {
      display: inline;
    }
  }
}
