.wrapper {
  height: 100%;

  @media (min-height: 667px) {
    height: calc(100vh - 58px);
  }

  &_scroll {
    & .tab-content {
      height: calc(100vh - 152px);
      overflow: auto;
    }
  }

  &__column {
    &_border {
      border-right: $border;
    }
  }

  & .btn {
    border: $border;
    border-radius: 4px;
    background: $color-button;
    margin-right: 3px;
    line-height: normal;
    min-width: 36px;

    &-outline-secondary {
      color: $color-text;
      &:hover {
        color: #fff;
        background-color: #adb5bd;
        border-color: #adb5bd;
      }
    }

    &-success {
      color: #fff;
      background-color: #02B875;
      border-color: #02B875;
    }
  }

  & .nav-link {
    padding: 11px 16px;
  }

  .nav {
    padding-left: 15px;
  }
}
