@import '../../../styles/variables';

.card {
    cursor: pointer;
    text-align: center;
    width: 257px;
    height: 194px;
    background: #FCFCFC;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    padding: 6px;
}

.card__header {
    color: $color-theme;
    font-size: 80px;
    padding: 8px 4px 6px 4px;
}

.card__header svg {
    width: 100%;
    height: 100%;
    //filter: $shadow-filter;
}

.card__title {
    font-size: 22px;
    color: #02B875;
    font-weight: 300;
}

.card:hover .card__title{
    text-decoration: underline;
}
