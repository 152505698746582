$color-theme: #02B875;
$color-button: #FEFEFE;
$color-text: #777777;
$color-panel: #FCFCFC;

$color-primary: #70bbfd;
$color-success: #02B875;
$color-warning: #f0ad4e;
$color-danger: #d9534f;

$border: 1px solid #F2F2F2;
