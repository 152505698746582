@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
  url('../fonts/roboto-regular-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
  url('../fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
