.patients {
  & .btn {
    border: $border;
    border-radius: 4px;
    background: $color-button;
    margin-right: 3px;
    line-height: normal;
    min-width: 36px;

    &-outline-secondary {
      color: $color-text;
      &:hover {
        color: #fff;
        background-color: #adb5bd;
        border-color: #adb5bd;
      }
    }
  }

  &__column {
    &_bg {
      background-color: $color-panel;
      border-right: $border;
      min-width: 310px;
    }
  }

  & .rt-table {
    height: calc(100vh - 186px);
  }

  & .ReactTable .rt-tr {
    .patients__action{
      cursor: pointer;
      color: $color-success;
      font-size: 25px;
    }
  }

  & .ReactTable .rt-tr.-selected {
    .patients__action {
      color: white;
    }
  }

  & .rt-tbody .rt-td {
    cursor: auto !important;
  }

  &__hadsCard {
    background: $color-button;
    border: 1px solid #CFCFCF;
    border-radius: 8px;
    color: $color-text;
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    height: 100%;
    margin-bottom: 25px;

    & .form-group {
      padding: 19px;
      padding-bottom: 0;
      margin: 0;
      width: 100%;
    }

    &:before {
      display: none;

      @media (min-width: 1020px) {
        display: flex;
      }
    }

    &:before {
      content: 'T';
      width: 41px;
      min-width: 41px;
      font-size: 24px;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #CFCFCF;
    }

    &_gray {
      background: #F8F8F8;

      &:before {
        content: 'Д';
      }
    }

    &_beforeNone {
      &:before {
        display: none;
      }
    }
  }

  &__table {
    overflow: auto;
  }

  .table {
    font-size: 13px;
    color: $color-text;

    @media (min-width: 576px) {
      font-size: 14px;
    }
  }

  .btn-scroll {
    padding: 10px 25px;
    margin-bottom: 20px;
    margin-right: 15px;
    font-size: 14px;
    overflow: hidden;
    z-index: 0;
    line-height: 1;
    opacity: 0;
    position: fixed;
    right: 15px;
    bottom: 15px;
    border-radius: 100%;
    background-color: $color-primary;
    border: none;
    width: 50px;
    height: 50px;
    transition: opacity ease .3s;

    &_show {
      opacity: 1;
    }
  }
}
