.tableFilter {
  padding: 8px 5px;
  border-bottom: $border;
  background-color: $color-panel;
  padding-left: 15px;

  & input, select {
    font-size: 11pt;
    line-height: normal;
    border-radius: 8px;
    color: $color-text;
    height: 24px;
    padding: 0 7px !important;
    text-overflow: ellipsis;

    &::placeholder {
      text-overflow: ellipsis;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
