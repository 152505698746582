.cards {
  padding: 17px 24px;
  background: #FFFFFF;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 14px;
  margin-left: 20px;
  width: 280px;

  &__title {
    margin: 0;
    font-size: inherit;
  }

  &__content {
    color: $color-text;
  }

  &__block {
    padding-bottom: 21px;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    font-size: 24px;

    & span {
      display: block;
      font-size: 12px;
      font-weight: 300;
      margin-left: 12px;
    }
  }
}
