.visitCard {
  padding: 20px;
  background: $color-panel;
  border: 1px solid #CFCFCF;
  box-sizing: border-box;
  border-radius: 8px;
  height: 100%;

  &_pointer {
    cursor: pointer;
  }

  &__add {
    font-size: 126px;
  }

  &__delete {
    cursor: pointer;
    color: $color-text;
  }
}
